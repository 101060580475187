import { Routes } from "@angular/router";
import { AuthGuard } from "../core/guards/auth.guard";
import { LayoutComponent } from "../layout/layout.component";
import { LoginComponent } from "./authentication/login/login.component";

export const routes: Routes = [
  {
    path: "",
    component: LayoutComponent,
    children: [
      { path: "", redirectTo: "home", pathMatch: "full" },
      {
        path: "home",
        loadChildren: () =>
          import("./home/home.module").then((m) => m.HomeModule),
        canActivate: [AuthGuard],
        data: { roles: ["SysAdmin"] },
      },
      {
        path: "users",
        loadChildren: () =>
          import("./users/users.module").then((m) => m.UsersModule),
        canActivate: [AuthGuard],
        data: { roles: ["SysAdmin"] },
      },
      {
        path: "preissued-cards-batch",
        loadChildren: () =>
          import("./preissued-card-batch/preissued-card-batch.module").then(
            (m) => m.PreissuedCardBatchModule
          ),
        canActivate: [AuthGuard],
        data: { roles: ["SysAdmin"] },
      },
      {
        path: "programs",
        loadChildren: () =>
          import("./programs/programs.module").then((m) => m.ProgramsModule),
        canActivate: [AuthGuard],
        data: { roles: ["SysAdmin"] },
      },
      {
        path: "preissued-card",
        loadChildren: () =>
          import("./preissued-card/preissued-card.module").then(
            (m) => m.PreissuedCardModule
          ),
        canActivate: [AuthGuard],
        data: { roles: ["SysAdmin"] },
      },
      {
        path: "agencies",
        loadChildren: () =>
          import("./issuing-entities/issuing-entities.module").then(
            (m) => m.IssuingEntitiesModule
          ),
        canActivate: [AuthGuard],
        data: { roles: ["SysAdmin"] },
      },
      {
        path: "integrationevents",
        loadChildren: () =>
          import("./integration-events/integration-events.module").then(
            (m) => m.IntegrationEventsModule
          ),
        canActivate: [AuthGuard],
        data: { roles: ["SysAdmin"] },
      },
      {
        path: "accounting",
        loadChildren: () =>
          import("./accounting/accounting.module").then(
            (m) => m.AccountingModule
          ),
        canActivate: [AuthGuard],
        data: { roles: ["SysAdmin"] },
      },
      {
        path: "lookups",
        loadChildren: () =>
          import("./lookup/lookup.module").then((m) => m.LookupModule),
        canActivate: [AuthGuard],
        data: { roles: ["SysAdmin"] },
      },
      {
        path: "transactions",
        loadChildren: () =>
          import("./transactions/transactions.module").then(
            (m) => m.TransactionsModule
          ),
        canActivate: [AuthGuard],
        data: { roles: ["SysAdmin"] },
      },
      {
        path: "stores",
        loadChildren: () =>
          import("./stores/stores.module").then((m) => m.StoresModule),
        canActivate: [AuthGuard],
        data: { roles: ["SysAdmin"] },
      },
      {
        path: "ach",
        loadChildren: () =>
          import("./ach-payments/ach-payments.module").then(
            (m) => m.AchPaymentsModule
          ),
        canActivate: [AuthGuard],
        data: { roles: ["SysAdmin"] },
      },
      {
        path: "reports",
        loadChildren: () =>
          import("./reports/reports.module").then((m) => m.ReportsModule),
        canActivate: [AuthGuard],
        data: { roles: ["SysAdmin"] },
      },
    ],
  },
  {
    path: "auth/login",
    component: LoginComponent,
  },
  {
    path: "error500",
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
  },

  // Not found
  {
    path: "**",
    redirectTo: "home",
  },
];
