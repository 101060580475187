import { Component, OnInit, ViewChild, Injector, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { User } from './../../core/models/user';
import { UserblockService } from '../sidebar/userblock/userblock.service';
import { SettingsService } from '../../core/settings/settings.service';
import { MenuService } from '../../core/menu/menu.service';
import { NotificationsService } from 'src/app/core/notifications/notifications.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/core/services/authentication.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    @ViewChild('selectIssuingEntity') selectIssuingEntity: ElementRef;

    navCollapsed = true; // for horizontal layout
    menuItems = []; // for horizontal layout
    router: Router;
    user: User;

    isNavSearchVisible: boolean;
    @ViewChild('fsbutton', { static: true }) fsbutton;  // the fullscreen button

    constructor(
        public menu: MenuService,
        public userblockService: UserblockService,
        public settings: SettingsService,
        public injector: Injector,
        private modalService: NgbModal,
        private authService: AuthenticationService,
        private readonly notificationsService: NotificationsService) {
       
        // show only a few items on demo
        this.menuItems = menu.getMenu().slice(0, 4); // for horizontal layout
        this.user = this.currentUser();
    }

    ngOnInit(): any {
        this.isNavSearchVisible = false;

        const ua = window.navigator.userAgent;
        if (ua.indexOf('MSIE ') > 0 || !!ua.match(/Trident.*rv\:11\./)) { // Not supported under IE
            this.fsbutton.nativeElement.style.display = 'none';
        }

        this.router = this.injector.get(Router);

        // Autoclose navbar on mobile when route change
        this.router.events.subscribe((val) => {
            // scroll view to top
            window.scrollTo(0, 0);
            // close collapse menu
            this.navCollapsed = true;
        });
    }

    currentUser(): User {
        try {
            const transformed = plainToClass( User, JSON.parse(localStorage.getItem('currentUser')));

            if (transformed) {
                if (Array.isArray(transformed)) {
                    return transformed[0];
                }
                return transformed;
            }

            return null;
        } catch (error) { return null; }
    }

    toggleUserBlock(event): any {
        event.preventDefault();
        this.userblockService.toggleVisibility();
    }

    openNavSearch(event): any {
        event.preventDefault();
        event.stopPropagation();
        this.setNavSearchVisible(true);
    }

    setNavSearchVisible(stat: boolean): any {
        this.isNavSearchVisible = stat;
    }

    getNavSearchVisible(): any {
        return this.isNavSearchVisible;
    }

    toggleOffsidebar(): any {
        this.settings.toggleLayoutSetting('offsidebarOpen');
    }

    toggleCollapsedSideabar(): any {
        this.settings.toggleLayoutSetting('isCollapsed');
    }

    isCollapsedText(): any {
        return this.settings.getLayoutSetting('isCollapsedText');
    }

    toggleFullScreen(event): any {

    }
    

    logout() {
        this.authService.logout();
    }
}
