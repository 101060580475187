import { Injectable } from '@angular/core';
import * as signalR from '@aspnet/signalr';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from './authentication.service';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SignalrService {

  // private onNewNotification = new Subject<NotificationDto>();
  // public onNewNotification$ = this.onNewNotification.asObservable();

  private onNotificationRead = new Subject<number>();
  public onNotificationRead$ = this.onNotificationRead.asObservable();

  private onAllNotificationsRead = new Subject<void>();
  public onAllNotificationsRead$ = this.onAllNotificationsRead.asObservable();

  private hubConnection: signalR.HubConnection;

constructor(
  private readonly authenticationService: AuthenticationService) { }

  public startConnection = () => {

    let options = {};
    if (environment.production) {
      options = { transport: signalR.HttpTransportType.LongPolling };
    }

    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.notificationsUrl, options)
      .configureLogging( signalR.LogLevel.Information )
      .build();

    this.hubConnection
      .start()
      .then( () => console.log('SignalR Connection Started'))
      .catch( err => console.error('Error while starting connection: ' + err));
  }

  public addNotificationsListener = () => {

    // this.hubConnection.on('NewNotification', (notification: NotificationDto) => {
    //   if (this.authenticationService.isAuthenticated) {
    //     const currentUser = this.authenticationService.currentUser().email.toString();
    //     if (notification.users?.indexOf(currentUser) !== -1) {
    //       this.onNewNotification.next(notification);
    //     }
    //   }
    // });

    this.hubConnection.on('NotificationRead', ({id}) => this.onNotificationRead.next(id));

    this.hubConnection.on('AllNotificationsRead', ((data: { email: string }) => {
      if (data.email === this.authenticationService.currentUser().email) {
        this.onAllNotificationsRead.next();
      }
    }));
  }

  checkUser(users: string[]): boolean {
    const currentUser = this.authenticationService.currentUser().email.toString();
    return users?.indexOf(currentUser) !== -1;
  }
}
