import { NgModule, Optional, SkipSelf } from '@angular/core';
import { SettingsService } from './settings/settings.service';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { MenuService } from './menu/menu.service';
import { ThemesService } from './themes/themes.service';
import { NotificationsService } from './notifications/notifications.service';
import { AuthenticationService } from './services/authentication.service';
import { SignalrService } from './services/signalr.service';

@NgModule({
  imports: [
  ],
  providers: [
    SettingsService,
    MenuService,
    ThemesService,
    NotificationsService,
    AuthenticationService,
    SignalrService
  ],
  declarations: []
})
export class CoreModule {
  constructor( @Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
}
}
