import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../services/base.service';
import { ToastrService } from 'ngx-toastr';

const swal = require('sweetalert2');

@Injectable()
export class NotificationsService extends BaseService {

    constructor(
        protected http: HttpClient,
        private toaster: ToastrService) {

        super(http);
    }

    success(title: string, message: string): any {
        this.toaster.success(message, title);
    }

    error(title: string, message: string): any {
        this.toaster.error(message, title);
    }

    info(title: string, message: string): any {
        this.toaster.info(message, title);
    }

    warning(title: string, message: string): any {
        this.toaster.warning(message, title);
    }

    confirm(title: string, html: string, confirmCallback?, cancelCallback?): Promise<any> {
        return new Promise( (resolve, reject) => {
            swal.fire({
                title,
                html,
                icon: 'warning',
                showCancelButton: true,

            }).then((result) => {
                if (result.isConfirmed) {
                    if (confirmCallback) { confirmCallback(); }
                    resolve('');
                } else {
                    reject();
                }
            });
        });
    }
}
