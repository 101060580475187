const Home = {
  text: "Home",
  link: "/home",
  icon: "bi bi-house-door",
  roles: ["SysAdmin"],
};

const Users = {
  text: "Users",
  link: "/users",
  icon: "bi bi-person",
  roles: ["SysAdmin"],
};

const PreissuedCards = {
  text: "Cards Batches",
  link: "/preissued-cards-batch",
  icon: "bi bi-credit-card-2-back-fill",
  roles: ["SysAdmin"],
};

const Programs = {
  text: "Programs",
  link: "/programs",
  icon: "bi bi-diagram-3-fill",
  roles: ["SysAdmin"],
};

const IssuingEntities = {
  text: "Issuance Partners",
  link: "/agencies",
  icon: "bi bi-heart-pulse",
  roles: ["SysAdmin"],
};

const IntegrationEvents = {
  text: "Integration Events",
  link: "/integrationevents",
  icon: "bi bi-gear-fill",
  roles: ["SysAdmin"],
};

const Accounting = {
  text: "Accounting",
  link: "/accounting",
  icon: "bi bi-postcard",
  roles: ["SysAdmin"],
};

const Lookups = {
  text: "Lookups",
  link: "/lookups",
  icon: "bi bi-search",
  roles: ["SysAdmin"],
  submenu: [
    {
      text: "Banks",
      link: "/lookups/banks",
    },
    {
      text: "Routing Numbers",
      link: "/lookups/routings",
    },
    {
      text: "Others",
      link: "/lookups",
    },
  ],
};

const Transactions = {
  text: "Transactions",
  link: "/transactions",
  icon: "bi bi-coin",
  roles: ["Administrator"],
};

const Farms = {
  text: "Farms",
  link: "/stores",
  icon: "bi bi-shop-window",
  roles: ["Administrator"],
};

const Ach = {
  text: "Payment Orders",
  link: "/ach",
  icon: "bi bi-bank",
  roles: ["Administrator"],
};
const Reports = {
  text: "Reports",
  link: "/reports",
  icon: "bi bi-journal-arrow-down",
  roles: ["Administrator"],
};

export const menu = [
  Home,
  Users,
  PreissuedCards,
  Programs,
  IssuingEntities,
  IntegrationEvents,
  Accounting,
  Lookups,
  Transactions,
  Farms,
  Ach,
  Reports,
];
