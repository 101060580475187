import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpEventType
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from './loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(private loaderService: LoaderService) { }

  removeRequest(req: HttpRequest<any>): any {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.requests.push(req);

    if(req.params.get('disableLoading')!='true'){
      this.loaderService.isLoading.next(true);
    }
    
    return new Observable(observer => {
      const subscription = next.handle(req)
        .subscribe(
          event => {
            if (event.type === HttpEventType.UploadProgress) {
              this.loaderService.setProgress(Math.round(100 * event.loaded / event.total));
              return;
            }
            if (event instanceof HttpResponse) {
              this.removeRequest(req);
              observer.next(event);
            }
          },
          err => { 
            this.removeRequest(req); 
            observer.error(err); 
          },
          () => {
            this.removeRequest(req); 
            observer.complete(); 
          });
      // teardown logic in case of cancelled requests
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }
}
