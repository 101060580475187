import { Component, HostBinding, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { LoaderService } from './core/loading/loader.service';
import { SettingsService } from './core/settings/settings.service';
import { SignalrService } from './core/services/signalr.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @HostBinding('class.layout-fixed') get isFixed(): any { return this.settings.getLayoutSetting('isFixed'); }
  @HostBinding('class.aside-collapsed') get isCollapsed(): any { return this.settings.getLayoutSetting('isCollapsed'); }
  @HostBinding('class.layout-boxed') get isBoxed(): any { return this.settings.getLayoutSetting('isBoxed'); }
  @HostBinding('class.layout-fs') get useFullLayout(): any { return this.settings.getLayoutSetting('useFullLayout'); }
  @HostBinding('class.hidden-footer') get hiddenFooter(): any { return this.settings.getLayoutSetting('hiddenFooter'); }
  @HostBinding('class.layout-h') get horizontal(): any { return this.settings.getLayoutSetting('horizontal'); }
  @HostBinding('class.aside-float') get isFloat(): any { return this.settings.getLayoutSetting('isFloat'); }
  @HostBinding('class.offsidebar-open') get offsidebarOpen(): any { return this.settings.getLayoutSetting('offsidebarOpen'); }
  @HostBinding('class.aside-toggled') get asideToggled(): any { return this.settings.getLayoutSetting('asideToggled'); }
  @HostBinding('class.aside-collapsed-text') get isCollapsedText(): any { return this.settings.getLayoutSetting('isCollapsedText'); }

  showLoadingIndicator = false;
  ajaxLoading = false;

  constructor(
    public settings: SettingsService,
    private router: Router,
    public loaderService: LoaderService,
    public signalRService: SignalrService) {
    this.router.events.subscribe((routerEvent: RouterEvent) => {
      if (routerEvent instanceof NavigationStart) {
        this.showLoadingIndicator = true;
      }

      if (routerEvent instanceof NavigationEnd) {
        this.showLoadingIndicator = false;
      }
    });

    this.loaderService.isLoading.subscribe(loading => {
      setTimeout(() => {
        if (loading !== this.ajaxLoading) {
          this.ajaxLoading = loading;
        }
      });
    });

    // this.signalRService.startConnection();
    // this.signalRService.addNotificationsListener();
  }

  ngOnInit(): void {
    // prevent empty links to reload the page
    document.addEventListener('click', e => {
      const target = e.target as HTMLElement;
      if (target.tagName === 'A' && ['', '#'].indexOf(target.getAttribute('href')) > -1) {
        e.preventDefault();
      }
    });
  }
}
