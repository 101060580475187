import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';


@NgModule({
    imports: [
    ],
    declarations: [
        LoginComponent,
    ],
    exports: [
        RouterModule
    ]
})
export class AuthenticationModule { }
