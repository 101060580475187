import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Pagination } from "src/app/core/dtos";
import { BaseService } from "src/app/core/services/base.service";
import { JwTockensDTO } from "src/app/dtos/jwtTockensDTO";
import { UserDTO } from "src/app/dtos/userDTO";
import { UserListDTO } from "src/app/dtos/userListDTO";
import { UserPasswordUpdateDTO } from "src/app/dtos/userPasswordUpdateDTO";
import { UserUpdateDTO } from "src/app/dtos/userUpdateDTO";
import { CustomHttpParamEncoder } from "src/app/helpers/http.params.encoder";

@Injectable({
  providedIn: "root",
})
export class UsersService extends BaseService {
  constructor(protected readonly http: HttpClient) {
    super(http);
    this.setBaseUrl("UserManagement");
  }

  getAll(
    filter: string,
    active: string,
    role: string,
    program: number,
    pageNumber: number = 1,
    sortColumn: string = "userName",
    sortDirection: number = 1
  ): Observable<Pagination<UserListDTO>> {
    // <UserListDTO>> {
    let params = new HttpParams({encoder: new CustomHttpParamEncoder()});
    if (filter) {
      params = params.append("filter", filter);
    }
    if (active) {
      params = params.append("active", active);
    }
    if (role) {
      params = params.append("role", role);
    }
    if (program) {
      params = params.append("programId", program.toString());
    }
    if (sortColumn && sortDirection) {
      params = params.append("sortColumn", sortColumn);
      params = params.append("sortDirection", sortDirection.toString());
    }
    params = params.append("page", pageNumber.toString());

    return this.http.get<Pagination<UserListDTO>>(this.buildUrl(""), {
      params,
    });
  }

  resendInvitation(id: number): Observable<any> {
    return this.http.post(this.buildUrl(`/${id}/resendInvitation`), null);
  }

  getProfile() {
    return this.http.get<UserDTO>(this.buildUrl(`/GetCurrentProfile`), {});
  }
  updateProfile(entity: UserUpdateDTO): Observable<any> {
    return this.http.put(this.buildUrl(`/UpdateCurrentProfile`), entity);
  }

  getIsVerified(id: number): Observable<any> {
    return this.http.get<any>(this.buildUrl(`/${id}/isVerified`));
  }

  activate(id: number): Observable<any> {
    // UserDTO> {
    return this.http.put<UserDTO>(this.buildUrl(`/${id}/Activate`), {});
  }

  deactivate(id: number): Observable<any> {
    // UserDTO> {
    return this.http.put<any>(this.buildUrl(`/${id}/Deactivate`), {});
    return this.http.put<UserDTO>(this.buildUrl(`/${id}/Deactivate`), {});
  }
  changePassword(dto: UserPasswordUpdateDTO): Observable<UserDTO> {
    return this.http.put<UserDTO>(
      this.buildUrl(`/UpdateCurrentProfilePassword`),
      dto
    );
  }
  getTokens(code: string): Observable<JwTockensDTO> {
    return this.http.get<JwTockensDTO>(
      this.buildUrl(`/GetUserJwtTokens/${code}`)
    );
  }
  refreshTokens(refreshToken: string): Observable<JwTockensDTO> {
    return this.http.get<JwTockensDTO>(
      this.buildUrl(`/RefreshUserJwtTokens/${refreshToken}`)
    );
  }
}
