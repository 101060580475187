import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class LoaderService {
    public isLoading = new BehaviorSubject(false);
    private _progress = new Subject<number>();
    public progress = this._progress.asObservable();

    constructor() {}

    public setProgress(value: number): any {
        this._progress.next(value);
    }
}
