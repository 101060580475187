import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { NotificationsService } from "../notifications/notifications.service";
import { AuthenticationService } from "../services/authentication.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private notifications: NotificationsService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUser();
    if (currentUser && currentUser.roles?.length > 0) {
      const roles = route.data["roles"] as Array<string>;
      if (roles && roles.length > 0) {
        let isInRole = false;
        roles.forEach(
          (role) => (isInRole = isInRole || currentUser.isInRole(role))
        );
        if (!isInRole) {
          this.notifications.error(
            "Access Denied",
            "You do not have permission"
          );
          this.router.navigate(["/home"]);
        }

        const now = new Date();
        const expired = new Date(currentUser.expiresTokenAt);
        var seconds = (now.getTime() - expired.getTime()) / 1000;
        if (seconds > currentUser.secondsRefreshToken) {
          this.notifications.error(
            "Authentication Required",
            "Please sign in to access this page"
          );
          this.authenticationService.clearStorage();
          this.router.navigate(["/auth/login"]);
        }

        return isInRole;
      }
    }

    this.authenticationService.clearStorage();
    this.router.navigate(["/auth/login"]);
    return false;
  }
}
