import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/core/services/authentication.service';

import { UserblockService } from './userblock.service';

@Component({
    selector: 'app-userblock',
    templateUrl: './userblock.component.html',
    styleUrls: ['./userblock.component.scss']
})
export class UserblockComponent implements OnInit {
    user: any;
    constructor(public userblockService: UserblockService, private authService: AuthenticationService) {

        const authenticatedUser = this.authService.currentUser();
        this.user = {
            firstName: authenticatedUser ? authenticatedUser.firstName : '',
            lastName: authenticatedUser ? authenticatedUser.lastName : '',
            roles: (authenticatedUser && authenticatedUser.roles) ? authenticatedUser.roles.join(' | ') : ''
        };
    }

    ngOnInit(): void {
    }

    userBlockIsVisible(): any {
        return this.userblockService.getVisibility();
    }

}
